<template>
  <div class="cont">
     <el-row>
        <el-link class="l-height-50 back_iconfont" type="primary" icon="iconfont iconfanhui1" @click="goBack()">
           <span>返回</span>
        </el-link>
     </el-row>
    <!-- 表单 -->
    <div class="det-box">
      <div class="box-next" style="width: 60%">
         <el-row class="m-bottom-15">
          <el-col :span="8" class="t-right l-height-40">
            <b class="needful">*</b>
            <span>工单标题：</span>
          </el-col>
          <el-col :span="16">
            <el-input  class="width-260" style="width: 80%;" maxlength = '20' v-model="title" placeholder="请输入工单标题"></el-input>
          </el-col>
        </el-row>
         <el-row class="m-bottom-15">
          <el-col :span="8" class="t-right l-height-40">
            <b class="needful">*</b>
            <span>工单类型：</span>
          </el-col>
          <el-col :span="16">
            <el-select v-model="typeCode" placeholder="请选择工单类型" >
              <el-option
                  v-for="item in typeCodeList"
                  :key="item.typeCode"
                  :label="item.typeName"
                  :value="item.typeCode">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
         <el-row class="m-bottom-15">
          <el-col :span="8" class="t-right l-height-40">
            <b class="needful">*</b>
            <span>接单部门：</span>
          </el-col>
          <el-col :span="16">
            <el-select v-model="department"  placeholder="请选择">
              <el-option
                  v-for="item in departments"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
         <el-row class="m-bottom-15">
          <el-col :span="8" class="t-right l-height-40">
            <b class="needful">*</b>
            <span>楼号：</span>
          </el-col>
          <el-col :span="16">
            <el-input  class="width-260" style="width: 80%;" maxlength = '20' v-model="buildId" placeholder="请输入楼号"></el-input>
          </el-col>
        </el-row>
         <el-row class="m-bottom-15" v-show="this.typeCode !== 'publicAreaClean' ">
          <el-col :span="8" class="t-right l-height-40">
            <span>房间号：</span>
          </el-col>
          <el-col :span="16">
            <el-input  class="width-260" maxlength = '20' style="width: 80%;" v-model="roomId" placeholder="请输入房间号"></el-input>
          </el-col>
        </el-row>
         <el-row class="m-bottom-15">
            <el-col :span="8" class="t-right l-height-40">
               <b class="needful">*</b>
               <span>是否紧急：</span>
            </el-col>
            <el-col :span="16">
               <el-radio v-model="isUrgent" label="1">是</el-radio>
               <el-radio v-model="isUrgent" label="0">否</el-radio>
            </el-col>
         </el-row>
         <el-row class="m-bottom-15">
          <el-col :span="8" class="t-right l-height-40">
            <span>备注信息：</span>
          </el-col>
          <el-col :span="16">
            <el-input  type="textarea" :rows="5" placeholder="请输入备注信息" v-model="content"></el-input>
          </el-col>
        </el-row>
         <el-row class="m-top-15 next-button" v-show="this.hotelId !== 'undefined'">
           <el-button class="m-right-30" type="primary" plain @click="handleReset" v-text="$t('msg.reset')">重置</el-button>
           <el-button class="bg-gradient" style="margin-top: 12px;" type="primary" @click="handleSave" v-text="$t('msg.save')">保存</el-button>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { system } from '@/api/interface/system'
   import {hotelMan} from "@/api/interface/oms";
   import { mapState } from 'vuex'
   export default {
      data(){
         return{
            /* 表单字段 */
            department: '',          // 当前部门
            departments: [],         // 所属部门列表
            content:'',              // 备注信息
            isUrgent: '1',           // 是否紧急
            buildId:'',              // 楼层号
            roomId:'',               // 房间号
            typeCode:'',             // 工单类型
            typeCodeList:[],         // 工单分类列表
            hotelId:'',
            title:'',
            /* 提示文本 */
            confirm: '确定',
            cancel: '取消',
            publish_success: '发布成功！',
            paymentMoney_no_null: '派单费用不能为空！',
            typeId_no_null: '分类不能为空！',
            title_no_null: '标题不能为空！',
            receiveOrgName:'',         //部门名称
         }
      },
      mounted() {
         this.hotelId = this.hotelInfo.id
          this.getCategorise()
         this.getDepartments()

         this.hotelId = this.hotelInfo.id
      },
      hotelInfo: {
         hotelId() {
            this.getDepartments()
         },
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
               this.hotelId = this.hotelInfo.id
               this.getCategorise()
               this.getDepartments()
            }
         },
         deep: true
      },
       computed: {
           ...mapState(['hotelInfo']),
       },
      methods: {

          // hiddenDisplay(){
          //     console.log(this.typeCode)
          // },
         // 获取工单分类列表
         getCategorise(){
            const url = hotelMan.workOrderCategory
            const param = { type: 1 }
            this.$axios.post(url, param).then(res => this.typeCodeList = res.records)
         },
         // 所属部门列表
         getDepartments(){
            const url = system.org
            const param = {
              companyId:this.tenantId,
                hotelId:this.hotelId,
                level:'2'
            }
            this.$axios.post(url, param, 'json').then(res => {
                this.departments = res.children

            })
         },
         // 重置表单
         handleReset(){
            this.title = ''
            this.typeCode = ''
            this.department = ''
            this.buildId = ''
            this.roomId = ''
            this.isUrgent = '1'
            this.content = ''
         },
         // 保存创建 问题
         handleSave(){
            // 表单验证
            if (!this.title) {
               this.$alert('工单标题不能为空！', '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.typeCode) {
               this.$alert('工单类型不能为空！', '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.department) {
               this.$alert('接单部门不能为空！', '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.buildId) {
               this.$alert('楼号不能为空！', '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }

            // 取出选中部门的名称
            let receiveOrgName = this.departments.map(item => {
               if (item.id === this.department) {
                 this.receiveOrgName = item.name
               }
            })

           const url = hotelMan.addworkorder
            const param = {
               title: this.title,
               content: this.content,
               typeCode: this.typeCode,
               receiveOrgName:this.receiveOrgName,
               receiveOrgId: this.department,
               isUrgent: this.isUrgent,
               buildId: this.buildId,
               roomId: this.roomId,
               status: 'NO_RECEIVED',
               workSource:'PC',
               workType: 1,
               hotelId:this.hotelId
            }
            this.$axios.post(url, param, 'json').then((res) => {
               if (res.success) {
                  this.$message({ message: this.publish_success, type: 'success' })
                  this.goBack()
               }
            })
         },
         //返回
         goBack(){
            this.$router.go(-1)
         }
      }
   }
</script>

<style scoped lang="scss">
.flex-pic{
  display: flex;
}
.old-pic{
  margin-right: 10px;
  border: 1px #eeeeee solid;
  border-radius: 6px;
}

.el-row{
  line-height: 30px;
  white-space: nowrap;
}
.det-box{
  /* border: 1px solid red; */
  background: white;
  padding: 10px 20px;
  .box-next{
    width: 60%;
    margin: 0 auto 20px;
    /deep/ .el-input__inner{
      height: 40px;
      line-height: 40px;
    }
  }
  .next-button{
    text-align: center;
    .el-button{
      width: 120px;
    }
  }
  .box-info{
    width: 80%;
    margin: 0 auto;
    /deep/ .el-input__inner{
      height: 40px;
      line-height: 40px;
    }
    .rate-number{
      /deep/ .el-rate__icon{
        height: 40px;
        line-height: 40px;
        font-size: 30px;
      }
    }
  }
  .box_list{
    .el-col-4{
      height: 50px;
      line-height: 50px;
      padding-left: 15px;
      border: 1px solid rgba(204, 204, 204, 1);
      border-width: 0 1px 1px 0;
      &:nth-child(6n){
        border-right: 0;
      }
      &:nth-last-child(1),&:nth-last-child(2),&:nth-last-child(3),&:nth-last-child(4),&:nth-last-child(5),&:nth-last-child(6){
        border-bottom: 0;
      }
    }
    .box-inline{
      display: inline-block;
      cursor: pointer;
    }
  }
  .box-bg{
    background: rgba(204, 204, 204, 0.3);
    border-bottom: 1px solid rgba(204, 204, 204, 1);
  }
  .box-border{
    border: 1px solid rgba(204, 204, 204, 1);
  }
}
.det-box::after{
  content: "";
  display: block;
  clear: both;
}
.el-col-16{margin-top:0.4rem;}
.el-select{width: 260px}
/deep/.el-textarea__inner{width:260px;}

.goBack{background: white;}
</style>
